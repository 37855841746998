import { useDataStore } from "./DataStoreProvider";

function Thanks(data) {
  const { appData } = useDataStore(); 
  
  fetch(appData.endpoint + "payment_intent", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
  .catch((err) => console.error(err));
  
  return (
    <div className="innerContent">
      <h1>Thanks!</h1>
      <h2>Confirmation ID: {data.id}</h2>
      <p>Your registration was successful. Please print this page. We look forward to seeing you!</p>
      <p>&nbsp;</p>
      <h3>Remember to Bring With:</h3>
      <ul>
        <li>Tent and sleeping bags</li>
        <li>Water bottle(s)</li>
        <li>Rain gear</li>
        <li>Evening chill gear</li>
        <li>Auction Items</li>
        <li>Musical Instrument or Poem</li>
      </ul>
      <p>&nbsp;</p>
      <p><strong>The camp has a strict no-dogs policy, please make other arrangements for your creature companions.</strong></p>
    </div>
  );
}

export default Thanks;