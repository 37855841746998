const HeartwoodLib = {
  calcTotal : (appData) => {
    const subtotal = 0 
      + 110 * (appData.adultFull ? parseInt(appData.adultFull) : 0)

      // $15 day + $12/meal
      
      + (appData.adultPartial ? 

//         (15 * parseInt(appData.adultPartial) * (appData.friDin ? 1 : 0)
//         + (15 * parseInt(appData.adultPartial)) * ((appData.satBre || appData.satLun || appData.satDin) ? 1 : 0)
//         + (15 * parseInt(appData.adultPartial)) * ((appData.sunBre || appData.sunLun || appData.sunDin) ? 1 : 0)
//         + (15 * parseInt(appData.adultPartial)) * (appData.monBre ? 1 : 0)

        ((15 * parseInt(appData.adultPartial)) * ((appData.friDin || appData.satBre || appData.satLun || appData.satDin || appData.sunBre || appData.sunLun || appData.sunDin || appData.monBre) ? 1 : 0)

  
        + 12 * parseInt(appData.adultPartial) * (appData.friDin ? 1 : 0)    
        + 12 * parseInt(appData.adultPartial) * (appData.satBre ? 1 : 0)    
        + 12 * parseInt(appData.adultPartial) * (appData.satLun ? 1 : 0)    
        + 12 * parseInt(appData.adultPartial) * (appData.satDin ? 1 : 0)    
        + 12 * parseInt(appData.adultPartial) * (appData.sunBre ? 1 : 0)
        + 12 * parseInt(appData.adultPartial) * (appData.sunLun ? 1 : 0)    
        + 12 * parseInt(appData.adultPartial) * (appData.sunDin ? 1 : 0)
        + 12 * parseInt(appData.adultPartial) * (appData.monBre ? 1 : 0))
      
      : 0)
      
      // + (appData.fullCabin ? 20 * parseInt(appData.fullCabin) : 0)
      
      /*
      + (appData.partialCabin ? (
        parseInt(appData.partialCabin) * (appData.friCabin ? 10 : 0)
        + parseInt(appData.partialCabin) * (appData.satCabin ? 10 : 0)
        + parseInt(appData.partialCabin) * (appData.sunCabin ? 10 : 0)
      ) : 0)
      */
      
      ;
    
    return (subtotal  
    + (appData.membership ? parseInt(appData.membership) : 0)).toFixed(2)
    ;
    
    // + (appData.scholarship ? parseFloat(appData.scholarship) : 0)
  
  }
};

export default HeartwoodLib;